import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/accounting`

const getContacts = () => {
  return axios.get(api + '/contacts', { withCredentials: true, credentials: 'include' })
}
const syncContacts = () => {
  return axios.get(api + '/synccontacts', { withCredentials: true, credentials: 'include' })
}
const updateXeroLineItems = ({ invoiceNumbers }) => {
  return axios.post(api + '/xerolineitems', { invoiceNumbers: invoiceNumbers }, { withCredentials: true, credentials: 'include' })
}
const getNewInvoiceNumbers = () => {
  return axios.get(api + '/newinvoicenumbers', { withCredentials: true, credentials: 'include' })
}
const getAppUsers = () => {
  return axios.get(api + '/appUsers', { withCredentials: true, credentials: 'include' })
}
const importXeroContact = (contact) => {
  return axios.post(api + '/importXeroContact', { contact: contact }, { withCredentials: true, credentials: 'include' })
}
const hasXeroConnection = () => {
  return axios.get(api + '/hasXeroConnection', { withCredentials: true, credentials: 'include' })
}
const updateXeroItems = (products) => {
  return axios.post(api + '/xeroItems', { products: [ ...products ] }, { withCredentials: true, credentials: 'include' })
}

export default {
  getContacts,
  syncContacts,
  updateXeroLineItems,
  getNewInvoiceNumbers,
  getAppUsers,
  importXeroContact,
  hasXeroConnection,
  updateXeroItems}

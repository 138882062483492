<template>
  <div>
    <h1 class="display-1 font-weight-bold mx-1 mb-3">
      Manage Xero Contacts.
    </h1>
    <v-btn
      color="success"
      class="mb-4"
      @click='syncXeroContacts()'
    >
      Sync Contacts
    </v-btn>
    <div v-if="showResults">
      <p>
        This following contacts will be synced
      </p>
      <v-divider class="mt-4"></v-divider>
      <v-data-table
        :headers="headers"
        :items="updates"
        :disable-pagination="true"
        :hide-default-footer=true
        :loading="loadingData"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.contact`]="{ item }">
            {{item.firstName}} {{item.lastName}}
        </template>
        <template v-slot:[`item.i`]="{ item, index }">
            {{index + 1}}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import AccountingApi from '@/api/accounting'
// import ReportApi from '@/api/admin/report'
import UserApi from '@/api/user'
// import _ from 'lodash'
export default {
  props: {
  },
  components: {
    ConfirmDialog
  },
  data () {
    return {
      updates: [],
      invoiceNumbers: [],
      invoices: [],
      users: [],
      loadingData: false,
      showResults: false,
      headers: [
        {
          text: '#',
          value: 'i'
        },
        {
          text: 'Sync',
          value: 'isUser'
        },
        {
          text: 'Name',
          value: 'name'
        },
        // {
        //   text: 'Contact',
        //   value: 'contact'
        // },
        {
          text: 'Logins',
          value: 'loginCount'
        },
        // {
        //   text: 'Date',
        //   value: 'updatedDateUTC'
        // },
        {
          text: 'Current Email',
          value: 'existingEmail'
        },
        {
          text: 'Email',
          value: 'emailAddress'
        },
        {
          text: 'Account',
          value: 'accountNumber'
        }

      ]
    }
  },
  async created () {
  },
  computed: {
  },
  watch: {},
  methods: {
    async syncXeroContacts () {
      this.showResults = true
      this.loadingData = true
      AccountingApi.syncContacts().then(response => {
        this.updates = response.data
        this.loadingData = false
      }).catch(err => console.log('woops', err))
    },
  }
}
</script>
